<template>
    <div class="title-item">
        <div class="vertical-block"></div>
        <p>选择电杆</p>
    </div>
    <div class="item-poles">
        <van-radio-group v-model="dataForm.pole_id">
            <van-radio v-for="(item, index) in poles" :name="item.pole_id">
                {{ item.asset ? item.asset.equipment : "数据一场" }}
            </van-radio>
        </van-radio-group>
    </div>
    <div class="title-item">
        <div class="vertical-block"></div>
        <p>巡更备注</p>
    </div>
    <van-field
        v-model="dataForm.remark"
        autosize
        maxlength="250"
        placeholder="请输入巡更备注"
        rows="2"
        show-word-limit
        type="textarea"
    />
    <div class="title-item">
        <div class="vertical-block"></div>
        <p>现场图片</p>
    </div>
    <div class="imageupload-item">
        <gk-image-uploader v-model:image="image" />
    </div>
    <div class="submit-button">
        <van-button :block="true" color="#DF5F0B" @click="onSubmit">确认打卡</van-button>
    </div>
</template>

<script setup>
import { ADD_PATROL_PUNCH, GET_PATROL_POLE_LIST } from "@/api/patrol";
import { onMounted, ref } from "vue";
import usePublic from "@/hooks/usePublic";
import { ADD_GOODS_AFTER_SALE } from "@/api/goods";
import { Toast } from "vant/lib/";

let { $route, $router, $store } = usePublic();

onMounted(() => {
    getPoleList();
});

// 图片
let image = ref(null);

let dataForm = ref({
    pole_id: null,
    remark: null
});

let poles = ref([]);

let getPoleList = () => {
    GET_PATROL_POLE_LIST({
        id: $route.params.id,
        limit: 10000
    }).then((res) => {
        poles.value = res.data.data.list;
        console.log(res.data.data);
    });
};

let onSubmit = () => {
    ADD_PATROL_PUNCH({
        ...dataForm.value,
        id: $route.params.id,
        images: [image.value]
    }).then((res) => {
        if (res.data.code === 200) {
            Toast({
                type: "success",
                message: "打卡成功",
                onClose: () => {
                    $router.go(-1);
                }
            });
        }
    });
};
</script>

<style lang="scss" scoped>
.item-poles {
    margin: 0 16px;
    padding: 0 0 15px 0;
    border-bottom: 1px solid #f0f0f0;
    p {
        font-size: 14px;
    }
    :deep(.van-radio) {
        margin-top: 15px;
        font-size: 14px;
    }
}

.title-item {
    padding: 20px 15px 10px 15px;
    font-size: 14px;
    font-weight: bold;
    display: flex;
    flex-direction: row;
    align-items: center;

    .vertical-block {
        width: 3px;
        height: 10px;
        background: #df5f0b;
        margin-right: 10px;
    }
}

.imageupload-item {
    padding: 0 15px;

    :deep(.gk-image-uploader) {
        width: 90px;
        height: 80px;

        .van-uploader {
            display: block;
            width: 100%;
            height: 100%;
        }
    }
}

.submit-button {
    margin-top: 30px;
    padding: 14px;
}
</style>
